var render = function render(){var _vm=this,_c=_vm._self._c;return _c('mp-flex',{attrs:{"direction":"column","align-items":"center","gap":"1"}},[_c('mp-flex',{attrs:{"as":"button","justify-content":"center","align-items":"center","width":"10","height":"10","background":_vm.color.default.background,"border-radius":"full","transition":"box-shadow 250ms linear","outline":"none","_hover":{
      background: _vm.color.hover.background,
    },"_focus":{
      background: _vm.color.focus.background,
      border: _vm.color.focus.border,
      boxShadow: _vm.color.focus.boxShadow,
    },"_active":{
      background: _vm.color.active.background,
    }},on:{"click":_vm.handleClick}},[_c('mp-flex',{attrs:{"transition":"all 250ms","color":_vm.color.default.icon,"_hover":{
        color: _vm.color.hover.icon,
      }}},[_c('mp-icon',{attrs:{"name":_vm.getIcon,"color":"currentColor","variant":_vm.icon === 'picture-in-picture' ? 'outline' : 'fill'}})],1)],1),_vm._v(" "),(_vm.label)?_c('mp-text',{attrs:{"color":_vm.isDisabled ? 'gray.400' : 'white',"font-size":"xs"}},[_vm._v(_vm._s(_vm.label))]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }