var render = function render(){var _vm=this,_c=_vm._self._c;return _c('mp-flex',{attrs:{"direction":"column","align-items":"center","gap":"1"}},[_c('mp-flex',{attrs:{"height":"10","gap":"1","padding":"2","rounded":"100px","bg":"#3a3f4b"}},[_c('mp-flex',{attrs:{"as":"button","justify-content":"center","align-items":"center","width":"6","height":"6","background":_vm.color.default.background,"border-radius":"full","transition":"box-shadow 250ms linear","outline":"none","_hover":{
        background: _vm.color.hover.background,
      },"_focus":{
        background: _vm.color.focus.background,
        border: _vm.color.focus.border,
        boxShadow: _vm.color.focus.boxShadow,
      }},on:{"click":_vm.handleClick}},[_c('mp-flex',{attrs:{"transition":"all 250ms","rounded":"full","background":_vm.color.default.background,"color":_vm.color.default.icon,"_hover":{
          background: _vm.color.hover.background,
          color: _vm.color.hover.icon,
        }}},[_c('mp-flex',{directives:[{name:"show",rawName:"v-show",value:(_vm.variant === 'unmute'),expression:"variant === 'unmute'"}],attrs:{"position":"relative","width":"20px","height":"20px","overflow":"hidden","rounded":"full"}},[_c('mp-box',{attrs:{"position":"absolute","top":"0","left":"0","width":"20px","height":"20px"}},[_c('mp-icon',{attrs:{"name":"mic","color":"currentColor","variant":"fill","width":"20px","height":"20px","display":"block"}})],1),_vm._v(" "),_c('mp-box',{ref:"mic",attrs:{"position":"absolute","top":"0","left":"0","width":"20px","height":"20px","background":_vm.color.default.background,"_hover":{
              background: _vm.color.hover.background,
            }}},[_c('mp-icon',{attrs:{"name":"mic","color":"currentColor","variant":"outline","width":"20px","height":"20px","display":"block"}})],1)],1),_vm._v(" "),(_vm.variant === 'mute')?_c('mp-icon',{attrs:{"name":"mic-mute","color":"currentColor","variant":"fill","width":"20px","height":"20px","display":"block"}}):_vm._e()],1)],1),_vm._v(" "),_c('mp-popover',{attrs:{"placement":"top-end"}},[_c('mp-popover-trigger',[_c('mp-flex',{attrs:{"as":"button","justify-content":"center","align-items":"center","width":"6","height":"6","background":_vm.color.default.background,"border-radius":"full","transition":"box-shadow 250ms linear","outline":"none","color":"gray.600","_hover":{
            background: _vm.color.hover.background,
            color: 'white',
          },"_focus":{
            background: _vm.color.focus.background,
            border: _vm.color.focus.border,
            boxShadow: _vm.color.focus.boxShadow,
          }}},[_c('mp-icon',{attrs:{"name":"caret-down","color":"currentColor"}})],1)],1),_vm._v(" "),_c('mp-popover-content',{attrs:{"max-width":"220px","bg":"dark","rounded":"md","shadow":"md","border-width":"1px","border-color":"gray.600","py":"1"}},[_vm._t("default",function(){return [_vm._v(" Popover Content ")]})],2)],1)],1),_vm._v(" "),_c('mp-text',{attrs:{"color":_vm.isDisabled ? 'gray.400' : 'white',"font-size":"xs"}},[_vm._v(_vm._s(_vm.label))])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }